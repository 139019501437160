import { createApp } from 'vue'
import router from './router'
import store from '@/store'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'

// Ant-Design在 V3 版本开始，默认使用 dayjs库替换了 momentjs 库。
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

const app = createApp(App)

import TIM from 'tim-js-sdk';
import COS from "cos-js-sdk-v5";
let options = {
  SDKAppID: 1600022775// 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
// 注册 COS SDK 插件
tim.registerPlugin({'cos-js-sdk': COS});
window.tim = tim
window.TIM = TIM
app.config.globalProperties.tim = tim
app.config.globalProperties.TIM = TIM



//正则
import test from '@/utils/reg.js'
app.config.globalProperties.$test = test

//全局挂在过滤器
import filters from '@/utils/index.js'
Object.keys(filters).map(v => {
  app.config.globalProperties[v] = filters[v]
})

// 高德地图密钥
app.config.globalProperties.AMapKey = '0d780353580b06822db70518a682334e'

// console.log(app.config.globalProperties)
app.use(store).use(router).use(Antd).mount('#app')
